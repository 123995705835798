.App {
  background-image: url("assets/bg1.png");
  background-repeat: repeat;
  background-size: contain; /* or 'contain', or specify a size */
  /* height: 1800px; */
}

.App-header {
  padding: 30px 50px 10px 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.App-logo {
  height: 100px;
  background-color: #faf9f085;
}

li {
  width: 180px;
  text-align: center;
}

.selectedMenu {
  border: solid #f36c33 2px;
  padding: 6px 0px;
  color: #f36c33;
  font-weight: 600;
  width: 180px;
}
.selectedMenu:hover {
  color: white !important;
  background-color: #f36d33;
}
.navLink {
  border: solid #a77541 2px;
  padding: 3px 0px;
  color: #a77541;
  font-weight: 500;
}
.navLink:hover {
  background-color: #a77541;
  color: #ffffff;
}

/* home css */

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text1 {
  color: #a77541;
  font-size: 6em;
  text-align: center;
}
.text2 {
  color: #a77641a2;
  text-align: center;
  font-size: 2em;
  margin-top: 15px;
}

.partner{
  margin-left: -15px;
}

.character {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.charImg {
  /* margin-top: -150px; */
  height: 300px;
  z-index: 999;
  /* margin-left: -250px; */
}
.charImg1 {
  /* margin-top: -150px; */
  height: 300px;
  z-index: 999;
  /* margin-left: -250px; */
}

.social {
  font-size: 1.2em;
  color: #a77541;
}
.lang:hover {
  cursor: pointer;
}
.divider {
  border-color: #a7764149;
  width: 90%;
}

.langActive {
  color: #f36c33;
  font-weight: 500;
}

.deliveryPartners {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: center;
  row-gap: 100px;
  z-index: 999;
  /* margin: 20px 40px 20px -477px; */
}
.chaskka {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #f36c33;
  font-weight: 600;
  font-size: 1.2em;
}
.wolt {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #f36c33;
  font-size: 1.2em;
  font-weight: 600;
  /* padding: 50px 115px; */
}
.foodora {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #f36c33;
  font-size: 1.2em;
  font-weight: 600;
}

.hover-scale {
  display: inline-block; /* or block, or any other suitable display value */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}

.hover-scale:hover {
  transform: scale(1.1); /* Scale the element on hover */
  color: #f36c33;
  border-color: #f36c33;
}

.introduction {
  border: solid #a77541 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 100px;
  padding: 0px 50px 50px 50px;
}

.intro-title {
  color: #a77541;
  text-align: center;
  font-size: 3.5em;
  margin-top: -50px;
  background-color: #faf9f0;
  width: 500px;
  /* height: 50px; */
}

.text3 {
  color:#a77541;
  text-align: center;
  font-size: 1.1em;
  margin-top: 50px;
  width: 80%;
}

.bookBtn {
  text-align: center;
  border: solid #a77541 2px;
  width: 350px;
  font-size: 2em;
  font-weight: 500;
  font-family: sans-serif;
  color: #a77541;
  margin: 30px;
  padding: 5px;
}

.menuBtn {
  text-align: center;
  border: solid #a77541 2px;
  width: 400px;
  font-size: 2em;
  font-weight: 500;
  font-family: sans-serif;
  color: #a77541;
  margin-bottom: 30px;
  padding: 5px;
}

.short {
  background-color: #a7754138;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.text {
  color: #f36c33;
  text-align: left;
  font-size: 2em;
  margin-top: 15px;
}

.text4 {
  color: #a77541;
  text-align: left;
  font-size: 1.1em;
  margin-top: 15px;
  width: 700px;
  margin-left: 50px;
}

.video {
  height: 370px;
}

.chaska {
  width: 250px;
}
.icon {
  color: #a77541;
}
.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #a7754138;
  padding: 10px 10px 0px 10px;
}
.footerNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.contactBG{
  display: none;
}

.footerContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 1.1em;
  font-weight: 500;
}

.contact_item {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
}

.footerIntro {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  width: 90%;
  padding: 10px 0px;
}
.details {
  display: flex;
  align-items: flex-start;
  width: 400px;
  color: #a77541;
  font-size: 1.1em;
  margin-bottom: 10px;
}
.intro {
  display: flex;
  align-items: flex-start;
  color: #a77541;
  font-size: 1em;
}

.footerMenu {
  display: flex;
  flex-direction: row;
  color: #a77541;
  gap: 10px;
}
.text-primary {
  font-size: 1.1em;
}

.copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* Menu css */

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a77541;
  text-align: center;
}
.text5 {
  font-size: 2em;
  margin-top: 40px;
  color: #f36c33;
}
.text6 {
  font-size: 1.3em;
  width: 1200px;
  margin: 20px;
}

.itemBlock{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  gap:15px;
  margin: 20px;
  flex-wrap: wrap;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #a776417c;
  border-radius: 10px;
}
.foodImg {
  height: 200px;
  border-radius: 10px 10px 0px 0px;
}
.text7 {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  width: 325px;
  margin: 10px 0px;
}
.text8 {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  
  text-align: right;
}

.bold {
  color: #f36c33;
  font-size: 1.3em;
  margin-bottom: 10px;
}

.fillets {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}
.fillerImg {
  height: 150px;
}

.bold1 {
  color: #f36c33;
  font-weight: 400;
  font-size: 1.1em;
  text-align: center;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 50%;
  margin-bottom: 100px;
  margin-top: 10px;
}

td,
th {
  border: 2px solid #a77541;
  text-align: center;

  padding: 8px;
  font-size: 1.2em;
}

.story {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a77541;
}

.textMargin {
  text-align: center;
  margin: 15px 200px;
}


.contact{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  margin: 15px 35px 35px 35px;
  height: 525px;
}



.contactImg{
  background-image: url("assets/contact.jpg");
  background-repeat: no-repeat;
  background-size: contain;
}



























/* media queries */

@media screen and (max-width: 880px) {
  .App-header {
    padding: 10px 0px 10px 25px;
  }
  .App-logo {
    height: 90px;
    margin-left: 50px;
  }

  .text1 {
    font-size: 2.5em;
    margin-top: 10px;
  }
  .text2 {
    font-size: 1.2em;
    margin-top: 5px;
  }

  .deliveryPartners {
    width: 90%;
    margin: 30px 0px;
  }

  .wolt {
    padding: 0px;
  }

  .introduction {
    margin: 20px 10px;
    padding: 0px 10px 10px 10px;
  }

  .intro-title {
    font-size: 2.5em;
    margin-top: -35px;
    background-color: #faf9f0;
    width: 400px;
    height: 45px;
  }

  .text3 {
    font-size: 1em;
    margin-top: 20px;
    width: 90%;
  }

  .bookBtn {
    width: 250px;
    font-size: 1.2em;
    margin: 15px;
  }

  .menuBtn {
    width: 300px;
    font-size: 1.2em;
  }

  .short {
    width: 100%;
    height: 950px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
  }
  .video {
    height: 100%;
  }

  .text {
    color: #f36c33;
    text-align: center;
    font-size: 1.5em;
    margin-top: 5px;
  }

  .text4 {
    text-align: center;
    border: solid #a77541 1px;
    font-size: 1em;
    margin-top: 0px;
    width: 100%;
    padding: 10px;
    margin-left: 0px;
  }

  .footer {
    padding-top: 50px;
  }

  .footerIntro {
    display: none;
  }

  .footerMenu {
    align-items: center;
  }

  .textMargin {
    margin: 15px 80px;
    font-size: 1.1em;
  }

  .bold {
    margin: 0px 30px;
    text-align: center;
  }

  .menu {
    margin: 0px 10px;
  }
  .text5 {
    font-size: 1.8em;
    margin-top: 20px;
  }
  .text6 {
    font-size: 1.2em;
    width: 90%;
  }

  .item {
    width: 90%;
    margin-top: 40px;
  }
  .foodImg {
    height: 250px;
  }

  .text7 {
    font-size: 1.2em;
  }

  .text8 {
    font-size: 1.2em;
  }

  .fillets {
    gap: 30px;
  }

  .fillerImg {
    height: 100px;
  }

  table {
    width: 80%;
  }



  .contact{
    flex-direction: column;
  }
  
 
  .details{
    width: 100%;
  }
  
  .footerContact{
    align-items: start;
  }
}

@media screen and (max-width: 480px) {
  .App-header {
    padding: 10px 0px 10px 25px;
  }
  .App-logo {
    height: 80px;
    margin-left: 0px;
  }

  .text1 {
    font-size: 2.5em;
    margin-top: 10px;
  }
  .text2 {
    font-size: 1.2em;
    margin-top: 5px;
  }

  .deliveryPartners {
    width: 90%;
    margin-right: 10px;
  }

  .wolt {
    padding: 0px;
  }

  .introduction {
    margin: 20px 10px;
    padding: 0px 10px 10px 10px;
  }

  .intro-title {
    font-size: 2.5em;
    margin-top: -35px;
    background-color: #faf9f0;
    width: 200px;
    height: 45px;
  }

  .text3 {
    font-size: 1em;
    margin-top: 20px;
    width: 90%;
  }

  .bookBtn {
    width: 250px;
    font-size: 1.2em;
    margin: 15px;
  }

  .menuBtn {
    width: 300px;
    font-size: 1.2em;
  }

  .short {
    width: 100%;
    height: 430px;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .text {
    color: #f36c33;
    text-align: center;
    font-size: 1.5em;
    margin-top: 5px;
  }

  .text4 {
    display: none;
    text-align: center;
    border: solid #a77541 1px;
    font-size: 1em;
    margin-top: 0px;
    width: 100%;
    padding: 10px;
    margin-left: 0px;
  }

  .footer {
    padding: 10px 15px 15px 15px;
  }

  .footerIntro {
    display: none;
  }

  .footerMenu {
    align-items: center;
  }

.footerNav{
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-primary{
  font-size: 0.8em;
}
.pie{
  margin-top: -22px;
}

.divider{
  width: 100%;
}
  .textMargin {
    margin: 15px 30px;
    font-size: 0.9em;
  }

  .bold {
    margin: 0px 30px;
    text-align: center;
  }

  .menu {
    margin: 0px 10px;
  }
  .text5 {
    font-size: 1.5em;
    margin-top: 20px;
  }
  .text6 {
    font-size: 1em;
    width: 100%;
  }
  .itemBlock{
    flex-direction: column;
    gap: 1px;
    margin: 5px;
    width: 100%;
  }

  .item {
    flex-direction: column;
    border: solid 1px #a776417e;
    width: 100%;
    margin-top: 40px;
    border-radius: 10px;
  }
  .foodImg {
    height: 210px;
    border-radius: 10px 10px 0px 0px;
  }

  .text7 {
    text-align: center;
    font-size: 1em;
    width: 100%;
    margin: 5px;
    padding: 10px;
  }

  .text8 {
    text-align: center;
    font-size: 1em;width: 100%;    
    padding: 5px;
    margin: 10px;
  }

  .fillets {
    gap: 30px;
  }

  .fillerImg {
    height: 100px;
  }

  table {
    width: 90%;
  }

.charImg{
  margin-top: 36px;
  height: 150px;
  margin-left: 5px;
}
.charImg1{
  margin-top: 36px;
  height: 150px;
  margin-left: 5px;
}


.contact{
  flex-direction: column;
  height: auto;
}

.contactBG{
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #a77541;
  font-size: 1em;
}

.details{
  width: 100%;
}

.footerContact{
  align-items: start;
}

.charImg1{
  display: none;
}

}
