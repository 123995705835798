 *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
  z-index: 99;
}





/* navbar */
.navbar {
  background-color: #faf9f085;
  /* background-color: #726e4662; */
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  min-height: 70px;
  border-radius: 10px;
  
}

/* logo  */
.logo {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
}

/* ul  */
.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

/* media queries */

@media screen and (max-width: 880px) {
  /* hamburger */
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .bar {
    background-color: #120f0b;
    width: 20px;
    height: 3px;
    display: block;
    margin: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  /* navMenu */

  .navMenu {
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 70px;
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: #faf9f0;
    padding: 10px;
  }

  .navMenu.active {
    left: 0;
  }

  .navMenu li {
    margin: 16px 0;
  }
}
